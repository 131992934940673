import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import ScrollToTop from './Components/Scrolltotop/ScrolltoTop';
import { Spin } from 'antd';

// Lazy load components
const HomePage = lazy(() => import('./Screens/HomePage'));
const About = lazy(() => import('./Screens/About'));
const Gallery = lazy(() => import('./Screens/Gallery'));
const Birthday = lazy(() => import('./Screens/Birthday'));
const ContactUs = lazy(() => import('./Screens/ContactUs'));
const Cafe = lazy(() => import('./Screens/Cafe'));

const MainComponents = () => {
    return (
        <>
            <BrowserRouter>
                <ScrollToTop>
                    <Suspense fallback={<div className='d-flex justify-content-center align-items-center vh-100' ><Spin /></div>}>
                        <Routes>
                            <Route path='/' element={<HomePage />} />
                            <Route path='/About' element={<About />} />
                            <Route path='/Gallery' element={<Gallery />} />
                            <Route path='/Birthday' element={<Birthday />} />
                            <Route path='/Contact-us' element={<ContactUs />} />
                            <Route path='/Cafe' element={<Cafe />} />
                        </Routes>
                    </Suspense>
                </ScrollToTop>
            </BrowserRouter>
        </>
    );
}

export default MainComponents;
